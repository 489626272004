code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
 * Public docs page
 */
.ant-table-small {
  border: none !important;
  border-radius: inherit !important;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}

/**
 * Editable input fields (outside of tables)
 * The same styles apply also in the readOnly mode so the UI shouldn't jump
 */
.editable-input {
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  padding: 4px 11px 4px 11px;
  margin: 0px 0px 0px 0px;
  min-height: 32px;
  overflow: auto;
}

.editable-input.editable {
  cursor: pointer;
}

.editable-input.editable:hover {
  border: var(--default-border);
}

.editable-input-tags {
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  padding: 4px 0px 4px 12px;
  margin: 5px 0 3px 0;
  cursor: pointer;
  min-height: 32px;
  overflow: auto;
}
.editable-input-tags:hover {
  border: var(--default-border);
}

.editable-input-tags.disabled:hover {
  border: 1px solid transparent;
  cursor: default;
}

/**
 * Editable cells (inside of tables)
 */
.editable-row .editable-cell {
  /*position: relative;*/
}

.editable-row .editable-cell-value,
.editable-row .description .editable-cell,
.editable-row .event-or-property-name .editable-cell {
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  padding: 4px 11px 4px 11px;
  margin: 0px 0px 0px 0px;
  min-height: 32px;
  /* Replace \n's with proper HTML new lines */
  white-space: pre-line;
}
.editable-row .editable-cell-tags-value {
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  padding: 3px 0px 4px 11px;
  margin: 0px 0px 0px 0px;
  min-height: 32px;
}
.editable-row .editable-cell-value-wrap {
  cursor: pointer;
}
.editable-row:hover .editable-cell-value-wrap {
  border: var(--default-border);
}

.editable-row input.ant-input,
.editable-section input.ant-input {
  height: 32px;
}
.ant-legacy-form-item-control {
  line-height: normal;
}

/* Used to reset AntD's strange white-space: nowrap that's causing table columns to grow (we're OK if tags wrap) */
.editable-row .ant-tag {
  white-space: normal;
}
.editable-row
  .ant-select-selection--multiple
  .ant-select-selection__choice__content {
  white-space: normal;
}

/* Other */
.editable-row:hover {
  background: unset;
}

.editable-row.selected td {
  position: relative;
}

.editable-row.ant-table-row-level-1 {
  background: #fafafa;
}

.editable-row.selected td:first-child:before {
  background: #314299;
  content: '';
  width: 5px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: unset !important;
}

tr:hover:not(.ant-table-expanded-row) > td {
  background: unset !important;
}

.event-prop-table .ant-table-tbody > tr > td {
  word-break: break-word;
}

.event-prop-table .ant-table-selection-column,
.event-prop-table .ant-table-selection-col {
  width: 32px !important;
  padding-right: 0px !important;
}

.event-prop-table .ant-table-tbody > tr.ant-table-row > td {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.stream-table .ant-table-thead > tr > th,
.event-prop-table .ant-table-thead > tr > th .ant-table-column-sorters,
.event-prop-table
  .ant-table-thead
  > tr
  > th:not(.ant-table-column-has-sorters) {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.event-prop-table td.ant-table-column-sort {
  background: transparent;
}

.event-prop-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background: none;
}

.ant-anchor-ink::before {
  background-color: white !important;
}

/* Don't add a 14px margin to last items in Markdown (needed for Markdown rendering which uses <p>'s, <ul>'s, etc.) */
.markdown > *:last-child {
  margin-bottom: 0px;
}

.ant-tabs-nav-wrap:last-child,
.ant-tabs-nav-container:last-child {
  margin-bottom: -1px; /* revert antd original style */
}

.ant-select-dropdown-menu {
  max-height: 270px;
}

.ant-select-dropdown-menu li.custom-event-nc-option {
  margin-top: 9px;
  position: relative;
  overflow: visible;
}

.ant-select-dropdown-menu li.custom-event-nc-option::before {
  content: ' ';
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  left: 0px;
  top: -5px;
}

a[disabled].ant-dropdown-trigger {
  color: rgba(0, 0, 0, 0.65);
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}

div.ant-tooltip {
  max-width: 300px;
}

.placeholder-color ::placeholder {
  color: #bfbfbf;
  opacity: 1 !important;
}

/* Chrome/Safari/Webkit */
.hide-scrollbars::-webkit-scrollbar {
  opacity: 0;
}

/* Firefox *!*/
@-moz-document url-prefix() {
  .hide-scrollbars {
    scrollbar-color: transparent transparent;
  }
}

.ant-select-multiple .ant-select-selection-placeholder {
  left: 12px;
}

/* antd computes alert color from primary color, so resetting it back to defaults */
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

.ant-badge-count {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  -webkit-transform: none;
  transform: none;
}
@-webkit-keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0;
  }
}
@keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
}
.ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
}
.ant-scroll-number-symbol {
  vertical-align: top;
}

.ant-select-dropdown {
  width: auto !important;
}

.ant-message {
  position: absolute;
  bottom: 20px !important;
  top: unset !important;
}

.ant-message-success .anticon {
  color: #27ae60 !important;
}

.reflex-container > .reflex-element {
  height: calc(100% - 2px);
}
