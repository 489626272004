@import 'cerulean/src/styles/themes/_base.scss';
/**
 * The base theme for stargate. Is built on top of Amplitude's base theme (`_base.scss`), overriding
 * certain values. This guarantees that all css variables that are used in our product are set
 * (either at the base level or by this theme's overrides)
 */
@mixin stargate-base-theme {
  @include base-theme;

  --default-border: 1px solid #f0f0f0;
  --border-radius: 2px;
}
