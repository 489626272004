@import '~cerulean/src/styles/themes/_base.scss';
@import 'cerulean/src/styles/_fonts.scss';
@import '~cerulean/src/styles/themes/_stargate-base.scss';

:root {
  @include stargate-base-theme;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  background-color: var($monochrome-minus-4);
}

html {
  font-family: $plex;
  box-sizing: border-box;
  height: 100%;
}

body {
  min-height: 100%;
  color: var($monochrome-plus-2);
  overscroll-behavior: none;
}
