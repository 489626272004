$monospace: roboto-mono, Source Code Pro, monospace;
$plex: IBM Plex Sans, Helvetica, Arial, sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
