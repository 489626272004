@import 'cerulean/src/styles/semantic/_colors.scss';
@import 'cerulean/src/styles/semantic/_border-radius.scss';
@import 'cerulean/src/styles/semantic/_elevation.scss';
@import 'cerulean/src/styles/semantic/_spacing.scss';
@import 'cerulean/src/styles/descriptive/_colors.scss';
@import 'cerulean/src/styles/descriptive/_spacing.scss';

/**
 * The base theme for Amplitude. All other themes should include this theme and build on top of it
 * as necessary, guaranteeing that all css variables that are used in our product are set (either
 * at the base level or by a theme-specific override)
 */
@mixin base-theme {
  /* ~~~~~~~~~~ BEGIN BASE COLOR PALETTE ~~~~~~~~~~ */
  /* hard-code colors that should not be themeable  */
  --amplitude-brand-color: #{$blue-0512};

  /* ~ (MAP SEMANTIC NAMES TO DESCRIPTIVE NAMES) ~~ */
  #{$monochrome-minus-5}: #{$monochrome-1000};
  #{$monochrome-minus-4}: #{$monochrome-0971};
  #{$monochrome-minus-3}: #{$monochrome-0906};
  #{$monochrome-minus-2}: #{$monochrome-0817};
  #{$monochrome-minus-1}: #{$monochrome-0727};
  #{$monochrome-base}: #{$monochrome-0610};
  #{$monochrome-plus-1}: #{$monochrome-0507};
  #{$monochrome-plus-2}: #{$monochrome-0386};
  #{$monochrome-plus-3}: #{$monochrome-0187};
  #{$monochrome-plus-4}: #{$monochrome-0103};
  #{$monochrome-plus-5}: #{$monochrome-0083};

  #{$primary-color-plus-5}: #{$blue-0105};
  #{$primary-color-plus-4}: #{$blue-0161};
  #{$primary-color-plus-3}: #{$blue-0242};
  #{$primary-color-plus-2}: #{$blue-0306};
  #{$primary-color-plus-1}: #{$blue-0437};
  #{$primary-color-base}: #{$blue-0512};
  #{$primary-color-minus-1}: #{$blue-0645};
  #{$primary-color-minus-2}: #{$blue-0814};
  #{$primary-color-minus-3}: #{$blue-0900};
  #{$primary-color-minus-4}: #{$blue-0944};
  #{$primary-color-minus-5}: #{$blue-0975};

  #{$secondary-color-minus-7}: #{$teal-0949};
  #{$secondary-color-minus-6}: #{$teal-0928};
  #{$secondary-color-minus-5}: #{$teal-0901};
  #{$secondary-color-minus-4}: #{$teal-0886};
  #{$secondary-color-minus-3}: #{$teal-0789};
  #{$secondary-color-minus-2}: #{$teal-0753};
  #{$secondary-color-minus-1}: #{$teal-0743};
  #{$secondary-color-base}: #{$teal-0715};
  #{$secondary-color-plus-1}: #{$teal-0621};
  #{$secondary-color-plus-2}: #{$teal-0517};
  #{$secondary-color-plus-3}: #{$teal-0461};
  #{$secondary-color-plus-4}: #{$teal-0404};
  #{$secondary-color-plus-5}: #{$teal-0284};

  #{$danger-color-minus-12}: #{$red-0972};
  #{$danger-color-minus-11}: #{$red-0960};
  #{$danger-color-minus-10}: #{$red-0934};
  #{$danger-color-minus-9}: #{$red-0902};
  #{$danger-color-minus-8}: #{$red-0849};
  #{$danger-color-minus-7}: #{$red-0703};
  #{$danger-color-minus-6}: #{$red-0663};
  #{$danger-color-minus-5}: #{$red-0655};
  #{$danger-color-minus-4}: #{$red-0606};
  #{$danger-color-minus-3}: #{$red-0582};
  #{$danger-color-minus-2}: #{$red-0548};
  #{$danger-color-minus-1}: #{$red-0521};
  #{$danger-color-base}: #{$red-0465};
  #{$danger-color-plus-2}: #{$red-0418};
  #{$danger-color-plus-3}: #{$red-0406};
  #{$danger-color-plus-4}: #{$red-0331};
  #{$danger-color-plus-5}: #{$red-0228};

  #{$categorical-color-01-base}: #{$blue-0512};
  #{$categorical-color-02-base}: #{$lime-0742};
  #{$categorical-color-03-base}: #{$purple-0327};
  #{$categorical-color-04-base}: #{$cyan-0807};
  #{$categorical-color-05-base}: #{$red-0680};
  #{$categorical-color-06-base}: #{$purple-0605};
  #{$categorical-color-07-base}: #{$purple-0795};
  #{$categorical-color-08-base}: #{$blue-0402};
  #{$categorical-color-09-base}: #{$orange-0578};
  #{$categorical-color-10-base}: #{$teal-0586};
  #{$categorical-color-11-base}: #{$yellow-0841};
  #{$categorical-color-12-base}: #{$pink-0454};

  #{$categorical-color-01-light}: #{$blue-0900};
  #{$categorical-color-02-light}: #{$lime-0952};
  #{$categorical-color-03-light}: #{$purple-0887};
  #{$categorical-color-04-light}: #{$cyan-0962};
  #{$categorical-color-05-light}: #{$red-0939};
  #{$categorical-color-06-light}: #{$purple-0931};
  #{$categorical-color-07-light}: #{$purple-0960};
  #{$categorical-color-08-light}: #{$blue-0901};
  #{$categorical-color-09-light}: #{$orange-0924};
  #{$categorical-color-10-light}: #{$teal-0927};
  #{$categorical-color-11-light}: #{$yellow-0966};
  #{$categorical-color-12-light}: #{$pink-0919};

  #{$positive-color-plus-2}: #{$mint-0428};
  #{$positive-color-plus-1}: #{$mint-0475};
  #{$positive-color-base}: #{$mint-0630};
  #{$positive-color-minus-1}: #{$mint-0949};
  #{$positive-color-minus-2}: #{$mint-0980};

  #{$negative-color-plus-2}: #{$red-0371};
  #{$negative-color-plus-1}: #{$red-0494};
  #{$negative-color-base}: #{$red-0563};
  #{$negative-color-minus-1}: #{$red-0931};
  #{$negative-color-minus-2}: #{$red-0968};

  #{$notice-color-plus-2}: #{$orange-0424};
  #{$notice-color-plus-1}: #{$orange-0495};
  #{$notice-color-base}: #{$orange-0636};
  #{$notice-color-minus-1}: #{$orange-0919};
  #{$notice-color-minus-2}: #{$orange-0976};

  #{$informative-color-plus-2}: #{$blue-0242};
  #{$informative-color-plus-1}: #{$blue-0306};
  #{$informative-color-base}: #{$blue-0512};
  #{$informative-color-minus-1}: #{$blue-0944};
  #{$informative-color-minus-2}: #{$blue-0975};

  #{$accent-color-01-plus-2}: #{$yellow-0604};
  #{$accent-color-01-plus-1}: #{$yellow-0738};
  #{$accent-color-01-base}: #{$yellow-0841};
  #{$accent-color-01-minus-1}: #{$yellow-0969};
  #{$accent-color-01-minus-2}: #{$yellow-0985};

  #{$accent-color-02-plus-2}: #{$teal-0412};
  #{$accent-color-02-plus-1}: #{$teal-0502};
  #{$accent-color-02-base}: #{$teal-0591};
  #{$accent-color-02-minus-1}: #{$teal-0959};
  #{$accent-color-02-minus-2}: #{$teal-0981};

  #{$accent-color-03-plus-2}: #{$pink-0367};
  #{$accent-color-03-plus-1}: #{$pink-0443};
  #{$accent-color-03-base}: #{$pink-0523};
  #{$accent-color-03-minus-1}: #{$pink-0923};
  #{$accent-color-03-minus-2}: #{$pink-0980};

  #{$accent-color-04-plus-2}: #{$indigo-0368};
  #{$accent-color-04-plus-1}: #{$indigo-0473};
  #{$accent-color-04-base}: #{$indigo-0523};
  #{$accent-color-04-minus-1}: #{$indigo-0911};
  #{$accent-color-04-minus-2}: #{$indigo-0976};

  /* ~~~~~~~~~~~ END BASE COLOR PALETTE ~~~~~~~~~~~ */

  /* ~~~~~~~~~~ BEGIN BASE SPACING PALETTE ~~~~~~~~~~ */
  #{$spacing-xx-small}: #{$spacing-2px};
  #{$spacing-x-small}: #{$spacing-4px};
  #{$spacing-small}: #{$spacing-8px};
  #{$spacing-medium}: #{$spacing-16px};
  #{$spacing-large}: #{$spacing-32px};
  #{$spacing-x-large}: #{$spacing-64px};
  /* ~~~~~~~~~~ END BASE SPACING PALETTE ~~~~~~~~~~ */

  /* ~~~~~~~~ BEGIN EXTENDED COLOR PALETTE ~~~~~~~~ */
  /* ~ (MAP SEMANTIC NAMES TO BASE PALETTE NAMES) ~ */
  #{$primary-text-color}: var($monochrome-plus-5);
  /* ~~~~~~~~~ END EXTENDED COLOR PALETTE ~~~~~~~~~ */

  /* ~~~~~~~~ BEGIN ELEVATION TOKENS ~~~~~~~~ */
  /* ~ (MAP SEMANTIC ELEVATION NAMES TO BASE ELEVATION NAMES) ~ */

  #{$elevation-base}: #{none};
  #{$elevation-plus-1}: #{0px 1px 4px rgba(0, 0, 0, 0.1)};
  #{$elevation-plus-2}: #{0px 2px 8px rgba(0, 0, 0, 0.1)};
  #{$elevation-plus-3}: #{0px 4px 16px rgba(0, 0, 0, 0.1)};
  #{$elevation-plus-4}: #{0px 6px 24px rgba(0, 0, 0, 0.1)};
  /* ~~~~~~~~~ END EXTENDED ELEVATION TOKENS ~~~~~~~~~ */

  /* ~~~~~~~~ BEGIN BORDER RADIUS TOKENS ~~~~~~~~ */
  /* ~ (MAP SEMANTIC BORDER RADIUS NAMES TO BASE BORDER RADIUS NAMES) ~ */
  #{$border-radius-base}: #{0px};
  #{$border-radius-plus-1}: #{2px};
  #{$border-radius-plus-2}: #{4px};
  #{$border-radius-plus-3}: #{8px};
  /* ~~~~~~~~~ END BORDER RADIUS TOKENS ~~~~~~~~~ */
}
